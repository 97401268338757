import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Rental from '../views/Rental.vue'
import Comparison from '../views/Comparison.vue'
import NotFound from '../views/NotFound.vue'
import { setTitle, setDescription, setKeywords } from '@/mixins';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: "このサイトについて"
    }
  },
  {
    path: '/rental',
    name: 'Rental',
    component: Rental,
    meta: {
      title: "さくらのレンタルサーバ まとめ"
    }
  },
  {
    path: '/comparison',
    name: 'Comparison',
    component: Comparison,
    meta: {
      title: "サービス比較"
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: "ページが見つかりません"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior( to, from, savedPosition ){ // eslint-disable-line no-unused-vars
    return { x: 0, y: 0 }
  }
})

router.beforeEach( ( to, from, next ) => {
  setTitle( to.meta.title )
  setDescription( to.meta.description )
  setKeywords( to.meta.keywords )
  next()
})

export default router
