<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <h3 class="text-h6 mb-4">
              さくらフル活用 へようこそ！
            </h3>
            <p class="text-body-1 font-weight-bold">
              月々缶ジュース一本分の値段（月額換算131円）で容量10GB、転送量40GB/日（2020年11月現在）のレンタルサーバーといえば？
            </p>
            <p class="text-body-1">
              ．．．そうです、<a href="https://www.sakura.ad.jp/" target="_blank">さくらインターネット</a>さんの「<a href="https://www.sakura.ne.jp/" target="_blank">さくらのレンタルサーバ</a>」が有名だと思います。
            </p>
            <p class="text-body-1">
              「さくらのレンタルサーバ」という名前、とてもかわいらしい響きですが、実は知る人ぞ知る老舗のレンタルサーバー屋さんだったりします。
            </p>
            <p class="text-body-1">
              現在のようにスマホが１人１台になり大手SNSを筆頭としたコンテンツ発信型のWebサービスが当たり前になる以前、インターネットといえばパソコンから見るような時代は、今のように個人や小さな企業が手軽に情報発信することはとてもハードルが高かったのです。
            </p>
            <p class="text-body-1">
              そんな時代から、個人法人を問わずお手ごろな価格でお手軽にホームページやWebサービスを立ち上げるための場所を提供し続け、時代の流れを追いつつ現在でも多種多様なサービスを提供されているのが、老舗レンタルサーバー屋さんの１つであるさくらインターネットさんです。
            </p>
            <p class="text-body-1">
              レンタルサーバー屋さんといえば、有名なのが大手通販サイトであるアマゾンさんが提供されている「AWS（アマゾン・ウェブ・サービス）」があります。<br />
              体力に余裕のあるような比較的大きな企業だとそちらを利用するケースが多いのですが、個人や小さな企業が借りるには「少し荷が重すぎる．．．」といったケースもあるかと思います。
            </p>
            <p class="text-body-1">
              なので、個人や小さな企業であれば前述のように<strong>月々缶ジュース一本分の値段で気軽に始められる</strong>「<a href="https://www.sakura.ne.jp/" target="_blank">さくらのレンタルサーバ</a>」を利用しない手はないです。<br />
              「さくらのレンタルサーバ」であれば、利用件数が45万件を突破した超有名サービスですし、さくらインターネットさんによる各種サポート文書も揃っています。
            </p>
            <p class="text-body-1">
              さらに、<strong>レンタルサーバーでは物足りなくなった</strong>とか、<strong>もっと自由度の高いサーバーを使いたい</strong>とか、<strong>トラフィック課金が無いクラウドサーバーを使いたい</strong>とか、そんな時は「<a href="https://www.sakura.ne.jp/" target="_blank">さくらのレンタルサーバ</a>」以外にも「<a href="https://vps.sakura.ad.jp/" target="_blank">さくらのVPS</a>」や「<a href="https://cloud.sakura.ad.jp/" target="_blank">さくらのクラウド</a>」などのサービスを存在するので、そちらを使っていくことも可能です。
            </p>
            <p class="text-body-1">
              せっかくお金を払ってサーバを借りるんだったら、フル活用しないとちょっと損ですよね？
            </p>
            <p class="text-body-1">
              当サイトでは「さくらのレンタルサーバ」を中心に、さくらインターネットさんのサービス全般のまとめや更新情報、FAQなどの、さくらユーザーさんにとって便利と思われるようなコンテンツを扱っていきます。<br />
              不定期に更新していく思いますので、そのときはまたよろしくお願いします。
            </p>
            <h3 class="text-h6 mb-4">
              更新やお知らせ
            </h3>
            <template v-for="item in news">
              <h4 :key="item.text" class="text-subtitle-1 mb-4">
                <v-icon>
                  mdi-calendar-month
                </v-icon> {{ formattedDate( item.date ) }} <span v-if="isWithin1Month( item.date )" class="red--text font-weight-bold">New!</span>
              </h4>
              <p :key="item.text" v-html="decodeEntities( item.html )" class="text-body-1">
              </p>
            </template>
            <h3 class="text-h6 mb-4">
              ナビゲーション
            </h3>
            <p class="text-body-1">
              さくらのレンタルサーバについては<router-link to="/rental">さくらのレンタルサーバ まとめ</router-link>へどうぞ。
            </p>
            <p class="text-body-1">
              サービス比較については<router-link to="/comparison">サービス比較</router-link>へどうぞ。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';
  import { textConvertMixins } from '@/mixins/TextConvert';
  
  import news from '@/assets/data/news.json'

  export default {
    name: 'Home',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    mixins: [
      textConvertMixins
    ],
    data: () => ({
      breadCrumbs: [
        "Home"
      ],
      news: news.data
    }),
    methods: {
      formattedDate: function( date ){
        return this.$moment( date ).format( 'YYYY/MM/DD' )
      },
      isWithin1Month: function( date ){
        return this.$moment.duration( this.$moment().diff( date ) ).asDays() < 30
      },
      combineNewsText: function( news ){
        return this.$moment( news.date ).format( 'YYYY/MM/DD' ) + "<br />\n" + this.decodeEntities( news.html )
      }
    },
  }
</script>
