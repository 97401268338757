<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <h3 class="text-h6 mb-4">
              サービス比較
            </h3>
            <p class="text-body-1">
              さくらインターネットさんのサービスには、「さくらのレンタルサーバ」以外にも「さくらのVPS」や「さくらのクラウド」などのサービスがあります。<br />
              どのサービスも、ホームページやWebサイトを公開するためのサーバーを用意するという点では似ていますが、できることやできないこと、それぞれ微妙に異なります。
            </p>
            <p class="text-body-1">
              特に、サーバー知識が詳しくない方、例えば、デザイナーの方や個人サイトを公開している方にとっては、この辺のハードルが高いと思います。<br />
              どれが最適なんだろう？と悩んでいる方も多いと思うので、初心者やサーバーに詳しくない方でも最適なサービスが選べるように、順に説明していきます。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              ※ このページは不定期に更新を行っていくと思います。
            </p>
            <h4 class="text-h6 mb-4">
              さくらのレンタルサーバ
            </h4>
            <p class="text-body-1">
              月々缶ジュース一本分の値段（月額換算131円）から始めることができる、お手軽なレンタルサーバーです。<br />
              有名なブログシステムである「WordPress（ワードプレス）」や、有名なオンラインショップシステムである「EC-CUBE（イーシーキューブ）」などは、無料の簡単インストール（約2分）だけでお手軽に利用することができるのが特徴です。<br />
              さらに、あらかじめ自分で用意したHTMLファイルなどをパソコンからアップロードして公開することもできます。
            </p>
            <p class="text-body-1">
              さくらのレンタルサーバは共用サーバー方式なので、1つのサーバーに複数のユーザーが同居することになります。<br />
              そのため、サーバー内でできることはさほど多くないですが、他サービスと比較して安価な価格や、サーバー自体のメンテナンスなどが不要になることがメリットです。
            </p>
            <p class="text-body-1">
              エンジニアさんには少し物足りないですが、デザイナーさんや趣味で個人サイトを持ちたい方などにおすすめです。
            </p>
            <p class="text-body-1">
              <a href="https://www.sakura.ne.jp/" target="_blank">さくらのレンタルサーバ</a>（外部サイト）
            </p>
            <h4 class="text-h6 mb-4">
              さくらのVPS
            </h4>
            <p class="text-body-1">
              VPSという名前、聞いたことがある方もいらっしゃるかと思いますが、要は仮想専用サーバーのことです。<br />
              1つのサーバー上に自分専用の仮想サーバーを用意することで、共用サーバーと比較して比較的自由にサーバーを使うことができるようになります。<br />
              レンタルサーバーよりも価格は高くなりますが、サーバー内での自由度を考えると、それでも安いほうだったりします。
            </p>
            <p class="text-body-1">
              VPSのサーバーではレンタルサーバーと違い、自分でアプリケーションのインストールやサーバーメンテナンスなどを全て行う必要があります。<br />
              デザイナーさんや趣味で個人サイトを持ちたい方にはこの点がハードルが高いため、それが「VPSはサーバーに詳しいエンジニア向け」と言われるようになったゆえんです。
            </p>
            <p class="text-body-1">
              ただ、最近ではデザイナーさんや趣味で個人サイトを持ちたい方でも手軽にVPSを利用できるようになってきていて、さくらのVPSでもそれを特徴として猛プッシュしているようです。
            </p>
            <p class="text-body-1">
              <a href="https://vps.sakura.ad.jp/" target="_blank">さくらのVPS</a>（外部サイト）
            </p>
            <h4 class="text-h6 mb-4">
              さくらのクラウド
            </h4>
            <p class="text-body-1">
              クラウド、つまり雲のことですが（某ファンタジーゲームのキャラクターのことではないです）、これはクラウドサーバーが世界のどこにあるかわからない仕組みになっているため、そのふわふわした存在を雲になぞらえてこう呼ばれます。
            </p>
            <p class="text-body-1">
              有名なクラウトサーバーとして、大手通販サイトであるアマゾンさんが提供されている「AWS（アマゾン・ウェブ・サービス）」があります。<br />
              AWSであれば、デザイナーさんも聞いたことがあるかと思います。
            </p>
            <p class="text-body-1">
              クラウドサーバーはVPSのサーバーとほぼ同じですが、サーバーの規模を簡単に拡大縮小できる点（スケーリング）が大きく異なります。<br />
              例えば、新しいサービスを始めようとしているスタートアップ（新興ベンチャー企業）の方たちや、イベントなどで突発的に使うWebサイトなどで利用するのに適しています。<br />
              特に、WebサイトやWebサービスを利用するユーザー（お客さん）の増減が明確に読み切れない場合に、VPSではなくクラウドを利用することで、そういった状況にも柔軟に対応できるようになります。
            </p>
            <p class="text-body-1">
              <a href="https://cloud.sakura.ad.jp/" target="_blank">さくらのクラウド</a>（外部サイト）
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default {
    name: 'Comparison',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "Comparison"
      ]
    }),
  }
</script>
